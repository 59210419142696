// Load Bootstrap init
import {initBootstrap} from "./components/bootstrap";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Components
import "./components/navbar"