// sticky menu

(function() {
  'use strict';

  window.addEventListener('scroll', function() {
    var window_top = window.pageYOffset || document.documentElement.scrollTop;
    if (window_top > 0) {
      document.querySelectorAll('.nav-header-classic, .fixed_menu').forEach(function(el) {
        el.classList.add('menu-fixed', 'animate__animated', 'animate__fadeInDown');
      });
    } else {
      document.querySelectorAll('.nav-header-classic, .fixed_menu').forEach(function(el) {
        el.classList.remove('menu-fixed', 'animate__animated', 'animate__fadeInDown');
      });
    }
  });

})();